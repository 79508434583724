import Vue from 'vue';
import Component from 'vue-class-component';
import CardProductAComponent from '@/components/card-product-a/card-product-a.component';
import { IItemProductA } from '@/shims-tsx';
import { DUNATI_DOMAIN, DUNATI_ORGANIZATION_SCHEMA, ROBOT_CONTENT } from '@/main';

@Component({
  components: {
    CardProductAComponent,
  },
})
export default class IndexPage extends Vue {

  public listProductsA: IItemProductA[] = [{
    title: 'SILLAS DE OFICINA',
    description: 'sofás y poltronas de oficinas',
    img: 'sillas.jpg',
    keyword: 'Sillas de oficina',
    background: '#FFFFFF',
    url: 'sillas-de-oficina',
  }, {
    title: 'ESCRITORIOS',
    description: 'y espacios de trabajo',
    img: 'escritorios.jpg',
    keyword: 'Escritorios de oficina',
    background: '#E9E9E9',
    url: '/escritorios',
  }, {
    title: 'MESAS',
    description: 'de reunión',
    img: 'mesas.jpg',
    keyword: 'Mesas de reunión',
    background: '#D1D1D1',
    url: '/mesas',
  }, {
    title: 'ALMACENAMIENTO',
    description: 'cajoneras y bibliotecas',
    img: 'gabinetes.jpg',
    keyword: 'Cajorenas, librerías, bibliotecas y gabinetes',
    background: '#EAEAEA',
    url: '/almacenamiento',
  }];
  constructor() {
    super();
    this.$options.dunatiHeadTags = {
      title: 'Dunati – Escritorios y Sillas de Oficina',
      metaTags: [
        /* Primaty Tags */
        {
          name: 'description',
          content: 'Somos fabricantes de muebles para oficinas. Creamos espacios que potencien el trabajo colaborativo mejorando la expericiencia de las personas.',
        },
        {
          name: 'robot',
          content: ROBOT_CONTENT,
        },
        /* Open Graph / Facebook */
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: `${DUNATI_DOMAIN}`,
        },
        {
          property: 'og:title',
          content: 'Dunati | Muebles y sillas de oficina',
        },
        {
          property: 'og:description',
          content: 'Somos fabricantes de muebles para oficinas. Creamos espacios que potencien el trabajo colaborativo mejorando la expericiencia de las personas.',
        },
        {
          property: 'og:image',
          content:  `${DUNATI_DOMAIN}/assets/images/home_page/og_home.jpg`,
        },
        /* Twitter */
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:url',
          content: `${DUNATI_DOMAIN}`,
        },
      ],
      /*
      metaInfo: {
        link: [
          {
            rel: 'canonical',
            href: `${DUNATI_DOMAIN}/escritorios/arista`
          }
        ]
      }
      */
    };

    this.$options.dunatiJsonld = DUNATI_ORGANIZATION_SCHEMA;
  }

  toUrl() {
    this.$router.push({ name: 'products' });
  }
  public mounted() {}
}

