import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IItemProductA } from '@/shims-tsx';

@Component({
  components: {},
})
export default class CardProductAComponent extends Vue {
  @Prop({ default: null })
  // @ts-ignore
  public data: IItemProductA;
  public mounted() {
  }
}

